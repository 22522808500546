<template>
  <Card>
    <template #content>
      <DataTable :value="comprovantes" responsiveLayout="scroll">
        <template #empty> Nenhum Comprovante encontrado. </template>
        <template #loading> Carregando. Por favor aguarde. </template>
        <Column field="tipo" header="Tipo"></Column>
        <Column field="titulo" header="Titulo"></Column>
        <Column field="corpo" header="Corpo"></Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import ComprovanteService from '@/service/ComprovanteService'

export default {
  data() {
    return {
      id: this.$route.params.idconsig,
      comprovantes: [],
    }
  },

  created() {
    this.service = new ComprovanteService(this.$http)
    this.service
      .getOneByIdConsignacao(this.id)
      .then((res) => {
        this.comprovantes = res
      })
      .catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Ocorreu um erro. Tente novamente!',
          life: 10000,
        })
      })
  },
}
</script>

<style></style>
