export default class ComprovanteService {
  constructor(http) {
    this._http = http
  }

  async getOneByIdConsignacao(id) {
    const { data } = await this._http.get(`/api/comprovante/consignacao/${id}`)
    return data
  }
}
